import{default as Loader}from'./loader/Loader'
import{default as Loading}from'./loader/Loading'
import{lazy}from'react'
const Navbar=lazy(()=>import('./navbar/Navbar'))
const Roadbar=lazy(()=>import('./navbar/Roadbar'))
const Sidebar=lazy(()=>import('./sidebar/Sidebar'))
const PieChart=lazy(()=>import('./charts/PieChart'))
const AreaChart=lazy(()=>import('./charts/AreaChart'))
const FileInput=lazy(()=>import('./fileInput/FileInput'))
const Pagination=lazy(()=>import('./pagination/Pagination'))
const AddItemButton=lazy(()=>import('./button/AddItemButton'))
const DraftEditor=lazy(()=>import('./draftEditor/DraftEditor'))
const Progressbar=lazy(()=>import('./progressbar/Progressbar'))
const AsyncSelect=lazy(()=>import('./asyncSelect/AsyncSelect'))
const DataFilters=lazy(()=>import('./dataFilters/DataFilters'))
const CountupCard=lazy(()=>import('./countupCard/CountupCard'))
const ScrollToTop=lazy(()=>import('./scrollToTop/ScrollToTop'))
const DeleteAlert=lazy(()=>import('./deleteAlerts/DeleteAlert'))
const ProgressText=lazy(()=>import('./progressText/ProgressText'))
const PasswordInput=lazy(()=>import('./passwordInput/PasswordInput'))
const ModalTextEditor=lazy(()=>import('./draftEditor/ModalTextEditor'))
const ImageUploadInput=lazy(()=>import('./fileInput/ImageUploadInput'))
const ProductsAddTable=lazy(()=>import('./productsAddTable/ProductsAddTable'))
const UploadImageToOrder=lazy(()=>import('./uploadImageToOrder/UploadImageToOrder'))
const SectionVisibilityDetector=lazy(()=>import('./sectionVisibilityDetector/SectionVisibilityDetector'))
export{Loader,Loading,Navbar,Roadbar,Sidebar,FileInput,Pagination,DraftEditor,AddItemButton,AsyncSelect,DataFilters,CountupCard,DeleteAlert,PasswordInput,SectionVisibilityDetector,Progressbar,PieChart,AreaChart,ProgressText,UploadImageToOrder,ImageUploadInput,ScrollToTop,ModalTextEditor,ProductsAddTable}